const baseOidcConfiguration = {
    service_worker_relative_url: '/OidcServiceWorker.js',
    redirect_uri: `${window.location.origin}/caregiver/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/caregiver/authentication/silent-callback`,
    service_worker_only: true,
    loadUserInfo: true,
    monitor_session: false
};

const getOidcConfig = (pathname) => {
    const patientConfig = {
        ...baseOidcConfiguration,
        authority: window.env.patient.authority,
        client_id: window.env.patient.clientId,
        scope: 'openid profile videocall_api offline_access',
        redirect_uri: `${window.location.origin}/patient/authentication/callback`,
        silent_redirect_uri: `${window.location.origin}/patient/authentication/silent-callback`,
    }

    const careGiverConfig = {
        ...baseOidcConfiguration,
        authority: `${window.env.authority}/oauth/v2/oauth-anonymous`,
        client_id: window.env.clientId,
        scope: 'openid profile roles videocall_api offline_access carecenter_api carecenters',
        redirect_uri: `${window.location.origin}/caregiver/authentication/callback`,
        silent_redirect_uri: `${window.location.origin}/caregiver/authentication/silent-callback`,
    }
    return pathname.includes('/patient') ? patientConfig : careGiverConfig;
}

export {getOidcConfig};
