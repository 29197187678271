const API_URLS = {
        CREATE_MEETING: `/api/videomeetings`,
        MEETING_TOKEN_PATIENT: (meetingId, participantId) => `/api/videomeetings/${meetingId}/getpatienttoken?participantId=${participantId}`,
        MEETING_TOKEN_CAREGIVER: (meetingId) => `/api/videomeetings/${meetingId}/gettoken`,
        HISTORY: `/api/videomeetings`,
        CREATE_PARTICIPANT: (meetingId) => `/api/videomeetings/${meetingId}/participants`,
        USERS: `/api/users`,
        PARTICIPANTS: (meetingId) => `/api/videomeetings/${meetingId}/participants/`,
        INVITE_SMS: (meetingId, participantId) => `/api/videomeetings/${meetingId}/participants/${participantId}/sms`,
        INVITE_EMAIL: (meetingId, participantId) => `/api/videomeetings/${meetingId}/participants/${participantId}/email`,
        KICK_USER: (meetingId, participantId) => `/api/videomeetings/${meetingId}/participants/${participantId}/kick`,
        EVENT_HUB: `/hubs/event`
}

const INTERNAL_URLS = {
    DASHBOARD: `caregiver`,
    MEETING: "meeting/:id",
    NO_MEETING: `nomeeting`
}

const EXTERNAL_URLS = {
    CAREGIVER_PORTAL_URL: `https://${window.env.careGiverPortalUrl}`,
};


export {API_URLS, EXTERNAL_URLS, INTERNAL_URLS}
